define(["angular",
        "./tripPickingListModule"

    ],
    function (angular,
              module) {
        "use strict";

        return module.service("tripPickingListService", [
            "tripPickingListServiceUrl",
            "tripPickingListServiceDependencies",
            "tripPickingListServiceImages",
            "messagesModal",
            "$http",
            "$filter",
            "$translate",
            "$q",
            "loadingService",
            function (tripPickingListServiceUrl, tripPickingListServiceDependencies, tripPickingListServiceImages, messagesModal, $http, $filter, $translate, $q, loadingService) {

                var pdfMake = tripPickingListServiceDependencies.getDependencies().pdfMake;
                var JSZip = tripPickingListServiceDependencies.getDependencies().JSZip;
                var FileSaver = tripPickingListServiceDependencies.getDependencies().FileSaver;

                return {
                    tripPickingListFromNodeIdentifiers: tripPickingListFromNodeIdentifiers,
                    exportTripPickingListsInZip: exportsTripsInZip
                };

                function getImage(image) {
                    return tripPickingListServiceImages.getImages()[image];
                }

                function tripPickingListFromNodeIdentifiers(nodeIdentifiers) {
                    return loadingService($http.post(tripPickingListServiceUrl.getTripPickingListUrlForNodeIdentifier(), nodeIdentifiers)
                        .success(function (tripPickingList) {
                            return exportSingleTripPDF(tripPickingList);
                        }));
                }

                function exportSingleTripPDF(tripPickingList) {
                    var generatedDocument = generateDocument(angular.copy(tripPickingList));
                    pdfMake.createPdf(generatedDocument.documentDefinition).download(generatedDocument.title);
                }

                function exportsTripsInZip(tripPickingList) {
                    var trips = angular.copy(tripPickingList);
                    var pdfPromiseHolder = trips.map(function (trip) {
                        var generatedDocument = generateDocument(trip);
                        return getPDFInBase64(generatedDocument.documentDefinition, generatedDocument.title);
                    });
                    return loadingService(
                        $q.all(pdfPromiseHolder).then(function (response) {
                            var zip = new JSZip();
                            response.forEach(function (holder) {
                                zip.file(holder.title, holder.blob, {base64: true});
                            });
                            zip.generateAsync({type: "blob"})
                                .then(function (content) {
                                    var title = "TRE - " + $translate.instant("tripPickingList.label.trips") + ".zip";
                                    FileSaver.saveAs(content, title);
                                });
                        })
                    );
                }

                function generateDocument(tripPickingList) {
                    var stopLines = extractStopsInLines(tripPickingList.stopsSummary);
                    return {
                        documentDefinition: generateDocumentDefinition(tripPickingList, stopLines),
                        title: "TRE - " + $translate.instant("tripPickingList.label.trip") + " " + tripPickingList.tripCode + ".pdf"
                    };
                }

                function getPDFInBase64(documentDefinition, title) {
                    return $q(function (resolve) {
                        return pdfMake.createPdf(documentDefinition).getBase64(function (data) {
                            return resolve($q(function (resolve) {
                                return resolve({blob: data, title: title});
                            }));
                        });
                    });
                }

                function extractStopsInLines(stopsSummary) {
                    var stopLines = [];
                    stopsSummary.forEach(function (stopSummary, index) {
                        var icon = getStopIcon(stopSummary);
                        var localityIdentifier = "(" + stopSummary.localityCode + ") - " + stopSummary.localityName;
                        var address = stopSummary.localityAddress;
                        var expectedArrival = $filter("date")(stopSummary.expectedArrival, "dd/MM/yyyy HH:mm");
                        var expectedDeparture = $filter("date")(stopSummary.expectedDeparture, "dd/MM/yyyy HH:mm");
                        var stopCount = index + 1;
                        stopLines.push(
                            {
                                style: "stopTable",
                                margin: [0, 0, 0, 10],
                                table: {
                                    body: [
                                        [
                                            {
                                                image: icon,
                                                width: 15,
                                                border: [false, false, false, false]
                                            },
                                            {
                                                text: $translate.instant("tripPickingList.label.stop") + " " + stopCount,
                                                bold: true,
                                                style: "labelInformation",
                                                border: [false, false, false, false]
                                            },
                                        ],
                                        [
                                            {
                                                width: 15,
                                                text: "",
                                                border: [false, false, false, false]
                                            },
                                            {
                                                text: [
                                                    {
                                                        text: $translate.instant("tripPickingList.label.locality") + ": "
                                                    },
                                                    {
                                                        text: localityIdentifier,
                                                        bold: false
                                                    }
                                                ],
                                                style: "stopInformations",
                                                border: [false, false, false, false]
                                            }
                                        ],
                                        [
                                            {
                                                width: 15,
                                                text: "",
                                                border: [false, false, false, false]
                                            },
                                            {
                                                text: [
                                                    {
                                                        text: $translate.instant("tripPickingList.label.address") + ": "
                                                    },
                                                    {
                                                        text: address,
                                                        bold: false
                                                    }
                                                ],
                                                style: "stopInformations",
                                                border: [false, false, false, false]
                                            }
                                        ],
                                        [
                                            {
                                                width: 15,
                                                text: "",
                                                border: [false, false, false, false]
                                            },
                                            {
                                                table: {
                                                    widths: ["*", "*"],
                                                    body: [
                                                        [
                                                            {
                                                                text: [
                                                                    {
                                                                        text: $translate.instant("tripPickingList.label.arrivalTime") + ": "
                                                                    },
                                                                    {
                                                                        text: expectedArrival,
                                                                        bold: false
                                                                    }
                                                                ],
                                                                style: "stopInformations",
                                                                border: [false, false, false, false]
                                                            },
                                                            {
                                                                text: [
                                                                    {
                                                                        text: $translate.instant("tripPickingList.label.departureTime") + ": "
                                                                    },
                                                                    {
                                                                        text: expectedDeparture,
                                                                        bold: false
                                                                    }
                                                                ],
                                                                style: "stopInformations",
                                                                border: [false, false, false, false]
                                                            }
                                                        ]
                                                    ]
                                                },
                                                layout: "noBorders",
                                                border: [false, false, false, false]
                                            }
                                        ]
                                    ]
                                },
                            }
                        );

                        if (index > 0) {
                            var lastLineStopBody = stopLines[stopLines.length - 1].table.body;
                            lastLineStopBody.push(
                                [
                                    {
                                        width: 15,
                                        border: [false, false, false, false],
                                        text: ""
                                    },
                                    {
                                        text: $translate.instant("tripPickingList.label.orders") + ": ",
                                        bold: true,
                                        color: "gray",
                                        style: "labelInformation",
                                        border: [false, false, false, false]
                                    },
                                ]
                            );
                            if (stopSummary.loadedOrdersSummary) {
                                setOrderRows(lastLineStopBody, stopSummary.loadedOrdersSummary, "LOAD");
                            }
                            if (stopSummary.unloadedOrdersSummary) {
                                setOrderRows(lastLineStopBody, stopSummary.unloadedOrdersSummary, "UNLOAD");
                            }

                            setAssign(lastLineStopBody);
                        }
                    });

                    return stopLines;
                }

                function getStopIcon(stop) {
                    if (stop.loadedOrdersSummary && stop.unloadedOrdersSummary) {
                        return getImage("bothIcon");
                    }
                    if (stop.loadedOrdersSummary) {
                        return getImage("loadingIcon");
                    }
                    return getImage("unloadingIcon");
                }

                function setOrderRows(stopBody, orderSummary, type) {
                    var bundle;
                    if (type === "LOAD") {
                        bundle = "tripPickingList.label.stop.type.load";
                    } else {
                        bundle = "tripPickingList.label.stop.type.unload";
                    }
                    stopBody.push(
                        [
                            {
                                width: 15,
                                border: [false, false, false, false],
                                text: ""
                            },
                            {
                                text: [
                                    {
                                        text: $translate.instant(bundle) + ": ",
                                        bold: true
                                    },
                                    {
                                        text: orderSummary.orderCodes.join(", "),
                                        bold: false
                                    }
                                ],
                                style: "stopInformations",
                                border: [false, false, false, false]
                            }
                        ],
                        [
                            {
                                width: 15,
                                text: "",
                                border: [false, false, false, false]
                            },
                            {
                                table: {
                                    widths: ["*", "*", "*"],
                                    body: [
                                        [
                                            {
                                                text: [
                                                    {
                                                        text: $translate.instant("tripPickingList.label.quantity") + ": "
                                                    },
                                                    {
                                                        text: orderSummary.items,
                                                        bold: false
                                                    }
                                                ],
                                                style: "stopInformations",
                                                border: [false, false, false, false]
                                            },
                                            {
                                                text: [
                                                    {
                                                        text: $translate.instant("tripPickingList.label.weight") + ": "
                                                    },
                                                    {
                                                        text: $filter("weight")(orderSummary.weight),
                                                        bold: false
                                                    }
                                                ],
                                                style: "stopInformations",
                                                border: [false, false, false, false]
                                            },
                                            {
                                                text: [
                                                    {
                                                        text: $translate.instant("tripPickingList.label.volume") + ": "
                                                    },
                                                    {
                                                        text: $filter("volume")(orderSummary.volume),
                                                        bold: false
                                                    }
                                                ],
                                                style: "stopInformations",
                                                border: [false, false, false, false]
                                            }
                                        ]
                                    ]
                                },
                                layout: "noBorders",
                                border: [false, false, false, false]
                            }
                        ]
                    );
                }

                function setAssign(stopBody) {
                    stopBody.push(
                        [
                            {
                                width: "15",
                                image: getImage("assign"),
                                border: [false, false, false, false],
                            },
                            {
                                text: "",
                                border: [false, false, false, false]
                            }
                        ],
                        [
                            {
                                width: "15",
                                text: "",
                                border: [false, false, false, false],
                            },
                            {
                                table: {
                                    body: [
                                        [
                                            {
                                                text: $translate.instant("tripPickingList.label.receiver.assign"),
                                                style: "assing",
                                                margin: [55, 0, 55, 0],
                                                border: [false, true, false, false]
                                            },
                                            {
                                                text: "",
                                                border: [false, false, false, false]
                                            }
                                        ]
                                    ],
                                },
                                border: [false, false, false, false]
                            }
                        ]
                    );
                }


                function generateDocumentDefinition(tripPickingList, stopLines) {
                    var distance = $filter("number")(tripPickingList.distance, 1);
                    var tollValue = $filter("currency")(tripPickingList.tollValue, "");
                    return {
                        pageSize: "A4",
                        pageMargins: [30, 30, 20, 20],
                        content: [
                            {
                                style: "tableHeader",
                                table: {
                                    widths: ["*", 15, "*", 15, "*", 15, "*"],
                                    body: [
                                        [
                                            {
                                                text: $translate.instant("tripPickingList.label.trip"),
                                                bold: true,
                                                color: "gray",
                                                border: [false, false, false, false],
                                                margin: [0, 5, 25, 0]
                                            },

                                            {
                                                image: getImage("header_distance"),
                                                width: 20,
                                                border: [false, false, false, false]
                                            },
                                            {
                                                text: $translate.instant("tripPickingList.label.totalDistance"),
                                                border: [false, false, false, false],
                                                style: "headerTitle"
                                            },
                                            {
                                                image: getImage("header_money"),
                                                width: 20,
                                                border: [false, false, false, false]
                                            },
                                            {
                                                text: $translate.instant("tripPickingList.label.tollValue"),
                                                border: [false, false, false, false],
                                                style: "headerTitle"
                                            },
                                            {
                                                image: getImage("header_stop"),
                                                width: 20,
                                                alignment: "left",
                                                border: [false, false, false, false]
                                            },
                                            {
                                                text: $translate.instant("tripPickingList.label.totalStops"),
                                                border: [false, false, false, false],
                                                style: "headerTitle"
                                            }
                                        ],
                                        [
                                            {
                                                text: tripPickingList.tripCode,
                                                style: "headerHighlight",
                                                border: [false, false, false, false],
                                                margin: [0, 0, 25, 0]
                                            },
                                            {
                                                text: [
                                                    {
                                                        text: distance,
                                                    },
                                                    {
                                                        text: " km",
                                                        style: "headerPrefix"
                                                    }
                                                ],
                                                style: "headerHighlight",
                                                colSpan: 2,
                                                border: [false, false, true, false]
                                            },
                                            {
                                                text: "",
                                            },
                                            {
                                                text: [
                                                    {
                                                        text: "R$ ",
                                                        style: "headerPrefix"
                                                    },
                                                    {
                                                        text: tollValue,
                                                    }
                                                ],
                                                style: "headerHighlight",
                                                colSpan: 2,
                                                border: [false, false, true, false]
                                            },
                                            {
                                                text: "",
                                            },
                                            {
                                                text: tripPickingList.numberOfStops,
                                                style: "headerHighlight",
                                                colSpan: 2,
                                                border: [false, false, false, false]

                                            },
                                            {
                                                text: "",
                                            }
                                        ],
                                        [
                                            {
                                                margin: [0, 5, 0, 0],
                                                table: {
                                                    widths: ["100%"],
                                                    body: [
                                                        [
                                                            {
                                                                text: "",
                                                                border: [false, false, false, false]
                                                            }
                                                        ],
                                                        [
                                                            {
                                                                text: [
                                                                    {
                                                                        text: getTripObservationLabel(tripPickingList.tripObservation)
                                                                    },
                                                                    {
                                                                        text: tripPickingList.tripObservation,
                                                                        bold: false
                                                                    }
                                                                ],
                                                                style: "stopInformations",
                                                                border: [false, false, false, false]
                                                            }
                                                        ]
                                                    ]
                                                },
                                                layout: "noBorders",
                                                colSpan: 7,
                                                border: [false, false, false, false]
                                            }
                                        ]
                                    ]
                                }
                            },
                            stopLines
                        ],
                        header: function (currentPage) {
                            if (currentPage === 1) {
                                return [
                                    {
                                        image: getImage("logo"), width: 140, alignment: "center", margin: [0, 15, 0, 0]
                                    },
                                ];
                            }
                        },
                        footer: function (currentPage, pageCount) {
                            var footer = "Página " + currentPage.toString() + " de " + pageCount;
                            return [
                                {
                                    text: footer, alignment: "right", margin: [0, 0, 10, 10], fontSize: 8
                                }
                            ];
                        },
                        pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage) {
                            if (currentNode.style === "stopTable") {
                                var nextStopTableNode = followingNodesOnPage.find(function (node) {
                                    return node.style === "stopTable";
                                });
                                if (!nextStopTableNode && nodesOnNextPage.length) {
                                    return true;
                                }
                            }
                            return false;
                        },
                        styles: {
                            assing: {
                                alignment: "center",
                                fontSize: 10
                            },
                            noBorders: {
                                border: [false, false, false, false]
                            },
                            headerHighlight: {
                                fontSize: 15,
                                bold: true,
                                color: "black",
                                alignment: "center"
                            },
                            headerTitle: {
                                alignment: "left",
                                margin: [0, 5, 0, 0],
                                bold: false
                            },
                            headerPrefix: {
                                fontSize: 14,
                                bold: false
                            },
                            labelInformation: {
                                fontSize: 12,
                            },
                            stopInformations: {
                                fontSize: 10,
                                bold: true
                            },
                            stopTable: {
                                margin: [0, 5, 0, 5]
                            },
                            tableHeader: {
                                bold: true,
                                fontSize: 13,
                                color: "black",
                                alignment: "center",
                                margin: [0, 50, 0, 0]
                            }
                        }
                    };
                }

                function getTripObservationLabel(tripObservation) {
                    return tripObservation ? $translate.instant("tripPickingList.label.observation") + ": " : "\n";
                }
            }]);
    });